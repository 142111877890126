//= React
import React from 'react';
import { Script } from "gatsby";
//= Components
import PreLoader from "components/PreLoader";
import ScrollToTop from "components/ScrollToTop";

//= Global CSS
import "styles/globals.css";
import "styles/preloader.css";
import SideMenu from 'components/ITCreative/SideMenu';
import BoeiChat from 'components/BoeiChat';

const MainLayout = ({ children, scrollTopText }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }
  return (
    <>
    <SideMenu />
    <BoeiChat />
      <PreLoader /> 
      
      { children }
      <ScrollToTop topText={scrollTopText} />

      <Script src="/assets/js/main.js"></Script>
    </>
  );
};

export default MainLayout;
