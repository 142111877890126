import { useEffect } from "react";
const BoeiChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://app.boei.help/embed/k/439a67a1-fee4-4ba3-b2d5-a7eaf0362c9b";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return null;
};
export default BoeiChat;
