import { Link } from 'gatsby';
import React from 'react';

const SideMenu = () => {
  const toggleSideMenu = (e) => {
    e.preventDefault();
    const side_menu = document.getElementById('side_menu');
    const side_overlay = document.querySelector('.side_overlay');

    side_menu && side_menu.classList.remove('show');
    side_overlay && side_overlay.classList.remove('show');
  }


  const handleMouseMove = (event) => {
    const dropDownToggler = event.target.classList.contains("dropdown-toggle")
      ? event.target
      : event.target.querySelector(".dropdown-toggle");
    const dropDownMenu = dropDownToggler?.nextElementSibling;

    dropDownToggler?.classList?.add("show");
    dropDownMenu?.classList?.add("show");
  };

  const handleMouseLeave = (event) => {
    const dropdown = event.target.classList.contains("dropdown")
      ? event.target
      : event.target.closest(".dropdown");
    const dropDownToggler = dropdown.querySelector(".dropdown-toggle");
    const dropDownMenu = dropdown.querySelector(".dropdown-menu");

    dropDownToggler?.classList?.remove("show");
    dropDownMenu?.classList?.remove("show");
  };
  return (
    <>
      <div className="side_menu style-7" id="side_menu">
        <a href="#0" className="side_menu_cls" onClick={toggleSideMenu}>
          <img src="/assets/img/icons/4dots.png" alt="" />
        </a>
        <div className="content">
          <div className="logo">
            <a href="#" className="w-100">
              <img src="/assets/img/logo.png" alt="" />
            </a>
          </div>
          <div className="pages_links">
            
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 ps-4">
            <li
              className="nav-item dropdown"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <a
                className="nav-link active dropdown-toggle"
                href="#"
                id="navbarDropdown1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Banking
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                <li>
                  <Link to="/savings-account" className="dropdown-item">
                  Savings Account
                 
                  </Link>
                </li>
                <li>
                  <Link to="/corporate-accounts" className="dropdown-item">
                    {" "}
                    Corporate Accounts
                  </Link>
                </li>
                
              </ul>
            </li>

            <li
              className="nav-item dropdown"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Loans
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                <li>
                  <Link to="/mt-loans" className="dropdown-item">
                    MTLoans
                  </Link>
                </li>
                <li>
                  <Link to="/ippis-loan" className="dropdown-item">
                    IPPIS Loan 
                  </Link>
                </li>
                <li>
                  <Link to="/carforcash" className="dropdown-item">
                  Car4Cash
                  </Link>
                </li>
                <li>
                  <Link to="/mtplus-loan" className="dropdown-item">
                    MTPlus Loan
                  </Link>
                </li>
                <li>
                  <Link to="/sme-loans" className="dropdown-item">
                  SME Loan
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className="nav-item dropdown"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Investment
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                <li>
                  <Link to="/treasury-note" className="dropdown-item">
                  Treasury Note

                  </Link>
                </li>
                <li>
                  <Link to="/fixed-deposit" className="dropdown-item">
                  Fixed Deposit
                  
                  </Link>
                </li>
                <li>
                  <Link to="/mudarabah" className="dropdown-item">
                  Mudarabah
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className="nav-item dropdown"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Asset Finance
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                <li>
                  <Link to="/mt-green-solar" className="dropdown-item">
                  MT Green Solar

                  </Link>
                </li>
                <li>
                  <Link to="/agric-finance" className="dropdown-item">
                  Agric Finance
                 
                  </Link>
                </li>
                <li>
                  <Link to="/auto-finance" className="dropdown-item">
                  Auto Finance
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className="nav-item dropdown"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Company
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                <li>
                  <Link to="/about" className="dropdown-item">
                  About us

                  </Link>
                </li>
                <li>
                  <Link to="/Leadership" className="dropdown-item">
                  Leadership

                  </Link>
                </li>
                <li>
                  <Link to="/gellery" className="dropdown-item">
                  Gallery
                 
                  </Link>
                </li>
                <li>
                  <Link to="/csr" className="dropdown-item">
                  Corporate Social Responsibility (CSR)
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link to="/blog" className="nav-link">
                blog
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                contact
              </Link>
            </li>
          </ul>
          </div>
          <div className="side_foot">
            <h5> get in touch </h5>
            <div className="row">
              <div className="col-lg-6">
                <a href="#"> <i className="fal fa-phone-alt me-2"></i> 0700MUTUALTRUST (070068882587878)  </a>
              </div>
              <div className="col-lg-6">
                <a href="#"> <i className="fal fa-envelope me-2 mt-4 mt-lg-0"></i> mails@mutualtrustmfb.com </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side_overlay"></div>
    </>
  )
}

export default SideMenu